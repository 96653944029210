$warning-color: #b80000;

.borderless-column td {
  border: 0 none;
}

.borderless-input {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
  outline: none;
}

.warningText {
  color: $warning-color;
}
