@import '../../styles/custom';

.loginMessage {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.messageContainer {
  height: calc(100% - 64px);
}

.messageHeader {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: $black;
}

.headerContainer {
  width: 512px;
}
