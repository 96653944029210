@font-face {
  font-family: 'HelveticaNowDisplayW05-Rg';
  src: url('https://egym.frontify.com/upload/screens/222097/06bc9947f6308c1b349153cbf3c12568-1578414942.eot#iefix');
  src: url('https://egym.frontify.com/upload/screens/222097/06bc9947f6308c1b349153cbf3c12568-1578414942.eot#iefix')
      format('eot'),
    url('https://egym.frontify.com/upload/screens/222097/5563d8b7d51bff82dc1f24643ab4e10f-1578414942.woff')
      format('woff'),
    url('https://egym.frontify.com/upload/screens/222097/0bd97faf667887dbda08213647ce221b-1578414942.woff2')
      format('woff2'),
    url('https://egym.frontify.com/upload/screens/222097/826a46047e8c5af1529a7cbb54336947-1578414942.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowDisplayW05-Bold';
  src: url('https://egym.frontify.com/upload/screens/222097/7995e139f103cc23acf7c9d3408e5633-1578414942.eot#iefix');
  src: url('https://egym.frontify.com/upload/screens/222097/7995e139f103cc23acf7c9d3408e5633-1578414942.eot#iefix')
      format('eot'),
    url('https://egym.frontify.com/upload/screens/222097/c2d898d3be0620b1cf5dadaf99caab09-1578414942.woff')
      format('woff'),
    url('https://egym.frontify.com/upload/screens/222097/c6d17b2963ca4be1e2b05169cec3f6e1-1578414942.woff2')
      format('woff2'),
    url('https://egym.frontify.com/upload/screens/222097/059902993187eca4092d13d959f5b200-1578414942.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowDisplayW05-It';
  src: url('https://egym.frontify.com/upload/screens/222097/4958f4a1ac001aec69b06ca42c2f917d-1578414942.eot#iefix');
  src: url('https://egym.frontify.com/upload/screens/222097/4958f4a1ac001aec69b06ca42c2f917d-1578414942.eot#iefix')
      format('eot'),
    url('https://egym.frontify.com/upload/screens/222097/b79fa1761ecf16384723d34427ae6320-1578414942.woff')
      format('woff'),
    url('https://egym.frontify.com/upload/screens/222097/72784ba0b63b3202442cc3cfe8c15d22-1578414942.woff2')
      format('woff2'),
    url('https://egym.frontify.com/upload/screens/222097/4557a24dc1fb1ecf5ce0ac1a0a881810-1578414942.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

$font-family-sans-serif: 'HelveticaNowDisplayW05-Rg, HelveticaNowDisplayW05-Bold, HelveticaNowDisplayW05-It',
  Helvetica, Montserrat, sans-serif;

$body-color: #3a3a3a;
$primary: #d15b05;
$secondary: #50bbc9;

$white: #fff !default;
$gray-100: #f2f2f2 !default;
$gray-200: #e9ecef !default;
$gray-300: #dcdcdc !default;
$gray-400: #ced4da !default;
$gray-500: #a6a6a6 !default;
$gray-600: #767676 !default;
$gray-700: #595959 !default;
$gray-800: #3a3a3a !default;
$gray-900: #262626 !default;
$black: #000 !default;
$dark: $gray-800;

$red: rgb(214, 0, 0);

$card-cap-bg: $gray-300;

$headings-color: $primary;

$headings-font-weight: 900;
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;

$border-color: #dcdcdc;
$border-radius: 0.7rem !default;
$border-radius-lg: 0.7rem !default;
$border-radius-sm: 0.7rem !default;

$input-color: #767676;
$input-bg: #fdfdfd;
$input-border-color: $border-color;
$input-focus-color: $input-color;
$input-focus-bg: #ffffff;
$input-focus-border-color: #dcdcdc;
$input-focus-width: 0;
$input-btn-focus-width: 0;
$input-placeholder-color: #cccccc;

$btn-font-weight: 100;
$btn-border-width: 0;
//$btn-line-height:             50%;
//$btn-padding-x:               28px;
//$btn-padding-y:               20px;

select {
  appearance: none;
  background: url('img/selectbox-arrow.png') no-repeat right center;
}

::selection {
  color: #ffffff;
  background: $primary;
}

.company-logo-wrapper {
  width: 360px;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, #eeeeee 75%, #808080 75%);
  background-size: 10px 10px;
  background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}

.company-logo {
  width: 360px;
  background: transparent;
  display: block;
  margin: 0 auto;
  border: black solid 1px;
}

.add-member-issues > div:last-child {
  margin-bottom: 0 !important;
}

.warning-icon {
  color: $red;
}

.success-report-link + .failed-report-link {
  margin-top: 10px;
}

.payment-links-table {
  thead {
    background-color: $gray-100;
  }
}

.flex {
  display: flex;
}

.flex-h {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.outlined-btn {
  border-radius: 20px;
  height: 100px;
  width: 120px;
  margin: 10px;
  border: 3px solid #808080;
  background-color: white;

  &:hover {
    background-color: #ddd;
  }
}

.text-black {
  color: $black;
}

.text-lg {
  font-size: x-large;
}

.cursor-pointer {
  cursor: pointer;
}

.simple-check-box {
  height: 20px;
  width: 20px;
  margin-left: 0;
  vertical-align: middle;
  accent-color: $primary;
}

$nav-pills-border-radius: 0;

@import '../../../node_modules/bootstrap/scss/bootstrap';
@import './spinner';
@import './stepper';
@import './table';
@import './notification.scss';
@import './emoji.scss';
@import './home.scss';
$mynewcolor: #77cccc;

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
  --bs-btn-disabled-color: #fff;
}

.btn-secondary {
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
}

.btn {
  --bs-btn-active-color: #fff;
}

.row {
  --bs-gutter-x: 1rem;
}

.table > :not(caption) > * > * {
  background-color: inherit;
}

.form-control:focus {
  box-shadow: none;
}

.inactiveRow {
  td {
    color: silver;
  }
}
