.offerTiersTable {
  background-color: #fff;
}

.noAvailableOfferTiersText {
  text-align: 'center';
  padding: 20px;
  font-weight: bold;
  color: 'silver';
  font-size: 18;
}
