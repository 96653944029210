$active: #d15b05;
$disabled: #3a3a3a;

.stepper-container {
  margin: 40px auto;
  padding: 1em;

  .stepper-wrapper {
    display: flex;
    justify-content: space-between;

    .step-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 20%;
      position: relative;
    }

    .step-number {
      border-radius: 80%;
      width: 30px;
      height: 30px;
      padding: 3px;
      text-align: center;
      margin-bottom: 1rem;
    }

    .divider-line {
      height: 1px;
      background-color: $disabled;
      position: absolute;
      width: 85%;
      top: 25%;
      left: 75%;
    }

    .step-number-active {
      background: $active;
      color: #fff;
    }

    .step-number-disabled {
      border: 1px solid $disabled;
    }

    .divider-line-active {
      background-color: $active;
    }

    .divider-line-disabled {
      background-color: $disabled;
    }

    .step-label-active {
      font-weight: bold;
    }
  }
}
