.welcome-container {
  display: flex;
  flex-direction: column;
}

.welcome-text {
  font-weight: 1000;
  font-size: 50px;
}

.welcome-image {
  border-radius: 25px;
  width: 600px;
  margin: auto;
  display: block;
}
