@import 'src/app/styles/custom.scss';

.iconSelect {
  background: url('../../styles/img/header-emojirain-ic.svg') no-repeat right center;
  border: none;
  cursor: pointer;
  &:hover {
    background: url('../../styles/img/header-emojirain-ic-dark.svg') no-repeat right center;
  }
}
