@import 'src/app/styles/custom.scss';

.availableOptions {
  cursor: pointer;
  color: $primary;
}

.availableOptionsTable {
  margin-left: 25%;
}
