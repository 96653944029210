@import 'src/app/styles/custom.scss';

.contentWidth {
  width: 512px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffebb2;
}

.joke {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
