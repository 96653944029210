.spinner-wrapper {
  opacity: 0.7;
  z-index: 10000;

  .spinner {
    background: url('../../../public/qt-pig.png') no-repeat center center;
    background-size: cover;
    width: 80px;
    height: 80px;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.inline-field-spinner {
  position: absolute;
  top: calc(50% - 12px);
  right: 10px;
}

.inline-dropdown-spinner {
  position: absolute;
  top: calc(50% - 12px);
  right: 50px;
}
